.home-01 ul li {
  padding: 0;
}
.home-01 a {
  padding: 25px 15px;
}
.home-01 a:hover {
  background-color: #83B63C;
}
#search-list {
  position: absolute;
  width: 100%;
}
.nav.tabbable {
  display: unset;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.box-attach-image {
  position: absolute !important;
  right: 10% !important;
  bottom: 3% !important;
}

.box-attach-video {
  position: absolute !important;
  right: 18% !important;
  bottom: 3% !important;
}

@media (max-width: 767.98px) {
  .nav.tabbable {
    overflow-x: auto;
    overflow-y: hidden;
    flex-wrap: nowrap;
    display: flex;
  }
  .nav.tabbable .nav-link {
    white-space: nowrap;
  }
  .home-01 a {
    padding: 20px;
  }
  .home-01 ul li {
    box-shadow: 0 0px 0px 0 rgba(0, 0, 0, 0.2), 0 0px 5px 0 rgba(0, 0, 0, 0.19);
    margin: 5px;
  }
}
.categori-div ul.services-ul li i {
  top: 10px;
}
